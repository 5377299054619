<template>
  <div class="auth-background">
    <v-container>
      <v-img
        :src="require('../assets/img/ngegame-text-logo.svg')"
        class="ngegame-logo"
      ></v-img>
      <v-row align="center">
        <v-col cols="12">
          <div class="d-flex" style="min-height: 100vh">
            <div
              class="d-flex flex-column align-self-center"
              style="width: 100%"
            >
              <div
                class="box-white d-flex flex-column align-self-center"
                style="width: 100%"
              >
                <label class="title-30-black align-self-center">Masuk</label>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <label class="mb-2">Email</label>
                  <v-text-field
                    label="Masukkan email kamu"
                    single-line
                    outlined
                    v-model="email"
                    :rules="emailRules"
                  ></v-text-field>
                  <label class="mb-2">Password</label>
                  <v-text-field
                    label="Masukkan kata sandi kamu"
                    single-line
                    type="password"
                    outlined
                    append-icon="mdi-eye-outline"
                    class="mb-6"
                    v-model="password"
                    :rules="passwordRules"
                  ></v-text-field>
                </v-form>
                <v-btn
                  color="#F8B32B"
                  class="mb-9 text-capitalize fs-16 fw-600"
                  style="color: white"
                  :loading="loading"
                  @click="onLogin"
                >
                  Masuk
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- <div class="center-component">
        <div class="box-white">
          Login2
        </div>
      </div> -->
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  name: "Login",

  components: {},
  data: () => ({
    valid: false,
    email: "",
    password: "",
    emailRules: [(v) => !!v || "Email is required"],
    passwordRules: [(v) => !!v || "Password is required"],
    authType: "basic_auth",
    code: "",
    loading: false,
  }),

  mounted() {
    if (this.$store.getters.getToken != null) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    login() {
      this.code = `${this.$router.push("Home")}`;
    },
    async onLogin() {
      // console.log(this.email);
      if (this.$refs.form.validate()) {
        this.loading = true;
        await axios
          .create({ withCredentials: false })
          .post(`${process.env.VUE_APP_API_URL}/v1/login`, {
            email: this.email,
            password: this.password,
            authType: this.authType,
          })
          .then((response) => {
            this.loading = false;
            this.$store.commit(
              "setIsDataComplete",
              response.data.data_complete
            );

            if (response.data.user.isAdmin == "0") {
              this.$toast.error("Anda tidak memiliki akses ke halaman ini");
            } else {
              this.$store.commit("setEmail", response.data.user.email);
              this.$store.commit("setToken", response.data.token);
              this.$store.commit("setId", response.data.user.id);
              this.$router.push("/dashboard");
            }
            // console.log(response);
          })
          .catch((error) => {
            this.$toast.warning(error.response.data.message);
            this.loading = false;

            console.log(error);
          });
      }
    },
  },
});
</script>

<style>
/* @import "./assets/css/global.css"; */
</style>
